@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

* {
  box-sizing: border-box;
  transition: all 0.15s ease-out 0s;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-break: break-word;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

span,
p,
body.body {
  font-size: 16px;
  color: #3D4152;
}

body.no-scroll {
  overflow: hidden;
}

small {
  font-size: 14px;
}


.list li {
  list-style-type: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-orange {
  color: #FF4514;
}
.terms-condition p{
  font-weight: 400!important;
}

@media only screen and (max-width: 639px) {
  span,
  p,
  body.body {
    font-size: 14px;
  }
}

/* @media (min-width:1400px){
  .container {
    max-width: 1360px;
  }
} */

*::-webkit-media-controls-panel {

  display: none!important;

  -webkit-appearance: none;

}

*::--webkit-media-controls-play-button {

  display: none!important;

  -webkit-appearance: none;

}

*::-webkit-media-controls-start-playback-button {

  display: none!important;

  -webkit-appearance: none;

}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.color-blue{
  color:#3655ff;
}